/* eslint-disable max-len */
import React from 'react';

const LearningCenterIcon = () => (
  <svg className="learning-center-icon dashboard-icon" viewBox="0 0 127.33 138.28">
    <path className="background" d="M138.55,95.07a38.36,38.36,0,1,0-67.28,25.19,30.82,30.82,0,0,1,7.78,20.22v.3A7.26,7.26,0,0,0,86.3,148h27.77a7.26,7.26,0,0,0,7.26-7.26h0a31.49,31.49,0,0,1,7.85-20.6A38.23,38.23,0,0,0,138.55,95.07Z" transform="translate(-36.34 -30.86)"/>
    <path className="line" d="M100.93,55.22a39.85,39.85,0,0,0-30.79,66,29.33,29.33,0,0,1,7.41,19.23v.3a8.77,8.77,0,0,0,8.75,8.76h27.77a8.77,8.77,0,0,0,8.76-8.76,30.05,30.05,0,0,1,7.48-19.62,39.84,39.84,0,0,0-29.38-65.94Zm-8.54,52.65H87.52A4.87,4.87,0,1,1,92.39,103Zm12.23,38.67H95.39V110.87h9.23Zm23.43-27.34a33,33,0,0,0-8.22,21.58,5.76,5.76,0,0,1-5.76,5.76h-6.45V110.87h4.86a7.87,7.87,0,1,0-7.86-7.87v4.87H95.39V103a7.87,7.87,0,1,0-7.87,7.87h4.87v35.67H86.3a5.76,5.76,0,0,1-5.75-5.76v-.3a32.32,32.32,0,0,0-8.15-21.2,36.92,36.92,0,0,1,27.8-61.07h.67a36.85,36.85,0,0,1,27.18,61Zm-20.43-11.33V103a4.87,4.87,0,1,1,4.86,4.87Z" transform="translate(-36.34 -30.86)"/>
    <rect className="line" x="48.27" y="122.27" width="30.79" height="3"/>
    <rect className="line" x="48.27" y="128.77" width="30.79" height="3"/>
    <rect className="line" x="51.85" y="135.28" width="23.64" height="3"/>
    <rect className="line" x="51.89" y="131.66" width="15.68" height="3" transform="translate(-111.89 47.56) rotate(-43.81)"/>
    <rect className="line" x="40.27" y="113.57" width="15.66" height="3" transform="translate(-75.31 -5.08) rotate(-21.59)"/>
    <rect className="line" x="42.62" y="86.13" width="3" height="15.55" transform="translate(-86.6 106.13) rotate(-89.37)"/>
    <rect className="line" x="47.02" y="65.03" width="3" height="15.58" transform="translate(-73.77 58.39) rotate(-67.14)"/>
    <rect className="line" x="59" y="47.2" width="3" height="15.43" transform="translate(-57.45 27.89) rotate(-44.92)"/>
    <rect className="line" x="76.88" y="35.21" width="3" height="15.24" transform="translate(-46.79 2.69) rotate(-22.69)"/>
    <rect className="line" x="98.04" y="30.87" width="3" height="15.06" transform="translate(-36.65 -30.05) rotate(-0.47)"/>
    <rect className="line" x="113.27" y="40.9" width="15.05" height="3" transform="translate(0.34 108.04) rotate(-68.26)"/>
    <rect className="line" x="131.53" y="52.6" width="14.95" height="3" transform="translate(-32.8 85.66) rotate(-46)"/>
    <rect className="line" x="144.05" y="70.33" width="14.81" height="3" transform="translate(-52.44 36.35) rotate(-23.79)"/>
    <rect className="line" x="148.41" y="91.49" width="15.22" height="3" transform="translate(-38.82 -26.56) rotate(-1.57)"/>
    <rect className="line" x="150.91" y="106.65" width="3" height="15.26" transform="translate(-44.63 185.72) rotate(-69.34)"/>
    <rect className="line" x="139.46" y="124.82" width="3" height="15.49" transform="translate(-88.44 114.77) rotate(-47.12)"/>
  </svg>
);

export default LearningCenterIcon;
