/* eslint-disable max-len */
import React from 'react';

const RecipesIcon = () => (
  <svg className="dashboard-icon" viewBox="0 0 147.79 124.62">
    <path className="background" d="M151.51,62.25A60.78,60.78,0,0,0,58.9,59V39.17H27.17V64c0,7.17,3.85,13.34,9.37,16.1l.1,8.49v65.85a6.4,6.4,0,1,0,12.79,0V127.11a60.8,60.8,0,0,0,109.75-1.93v29.21a6.39,6.39,0,1,0,12.78,0V39.17C162.39,39.17,154.53,50.2,151.51,62.25Z" transform="translate(-25.67 -37.67)"/>
    <path className="background" d="M172,39.17V154.39a6.4,6.4,0,0,1-6.39,6.4h0a6.4,6.4,0,0,1-6.39-6.4V100s-9-8.21-9-27.39C150.18,57.4,159.65,39.17,172,39.17Z" transform="translate(-25.67 -37.67)"/>
    <path className="line" d="M103.84,60.94a39,39,0,1,0,39,39A39.09,39.09,0,0,0,103.84,60.94Zm0,75.08a36,36,0,1,1,36-36A36.08,36.08,0,0,1,103.84,136Z" transform="translate(-25.67 -37.67)"/>
    <path className="line" d="M172,37.67c-9.51,0-17.47,9.88-21.12,21.48A62.12,62.12,0,0,0,60.4,55.4V39.17h-3V64c0,6.53-3.47,12.38-8.83,14.9l-.89.41.25,9.82v65.31a4.9,4.9,0,1,1-9.79,0V88.52L38,79.12l-.82-.41C32,76.12,28.67,70.33,28.67,64V39.17h-3V64a19.49,19.49,0,0,0,9.38,17l.09,7.59v65.85a7.9,7.9,0,1,0,15.79,0V132.81a62.23,62.23,0,0,0,106.75-1.54v23.12a7.89,7.89,0,1,0,15.78,0V37.67ZM103.84,159.29A59.35,59.35,0,0,1,50.93,126.7V89l-.19-7.93C56.63,77.9,60.4,71.28,60.4,64V59.7a59.13,59.13,0,0,1,89.47,3,43.93,43.93,0,0,0-1.19,9.92c0,17.25,7,25.93,9,28v24.19A59.37,59.37,0,0,1,103.84,159.29Zm66.62-4.9a4.89,4.89,0,1,1-9.78,0V99.32l-.49-.45c-.09-.08-8.51-8-8.51-26.28,0-13.81,8.25-30.36,18.78-31.82Z" transform="translate(-25.67 -37.67)"/>
    <rect className="line" x="10.11" y="1.5" width="3" height="30.34"/>
    <rect className="line" x="21.61" y="1.5" width="3" height="30.34"/>
  </svg>
);

export default RecipesIcon;
