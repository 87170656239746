/* eslint-disable max-len */
import React from 'react';

const OurFarmsIcon = () => (
  <svg className="dashboard-icon our-farms" viewBox="0 0 131.54 122.56">
    <path className="background" d="M95.15,51.3,68.74,95.6a1.61,1.61,0,0,1-2.2.56A1.64,1.64,0,0,1,66,95.6L39.58,51.3a32.35,32.35,0,1,1,55.57,0Z" transform="translate(-1.59 -0.91)"/>
    <path className="background" d="M74.88,85.31l20.27-34a32.34,32.34,0,1,0-55.57,0l20.27,34C46.18,86.47,36,90.72,36,95.8c0,6,14,10.8,31.32,10.8s31.31-4.84,31.31-10.8C98.67,90.72,88.54,86.47,74.88,85.31Z" transform="translate(-1.59 -0.91)"/>
    <path className="line" d="M81.75,76.71,96.44,52.07h0a33.85,33.85,0,1,0-58.15,0L53,76.71C25.83,78.9,1.59,87.18,1.59,99.8c0,14.81,33.44,23.67,65.77,23.67s65.77-8.86,65.77-23.67C133.13,87.18,108.9,78.9,81.75,76.71ZM37.47,27.17A30.84,30.84,0,1,1,93.86,50.53l-26.4,44.3c0,.06-.1.07-.18,0L40.87,50.53A30.59,30.59,0,0,1,37.47,27.17ZM64.7,96.37a3.11,3.11,0,0,0,5.33,0l5.65-9.48c13,1.24,21.49,5.24,21.49,8.91,0,4.4-12.24,9.3-29.81,9.3s-29.82-4.9-29.82-9.3c0-3.67,8.52-7.67,21.51-8.91Zm2.66,24.1c-37.55,0-62.77-10.69-62.77-20.67,0-8.81,19.66-18.16,50.09-20.24l2.68,4.5C45.09,85.47,34.54,89.48,34.54,95.8c0,8,16.91,12.3,32.82,12.3s32.81-4.31,32.81-12.3c0-6.31-10.54-10.32-22.81-11.73l2.69-4.51C110.47,81.64,130.13,91,130.13,99.8,130.13,109.78,104.91,120.47,67.36,120.47Z" transform="translate(-1.59 -0.91)"/>
    <path className="line" d="M80.34,33.45a13,13,0,1,0-13,13A13,13,0,0,0,80.34,33.45Zm-23,0a10,10,0,1,1,10,10A10,10,0,0,1,57.39,33.45Z" transform="translate(-1.59 -0.91)"/>
  </svg>
);

export default OurFarmsIcon;
