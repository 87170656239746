import React, { useEffect } from 'react';
import './dashboard.scss';
import ArrowIcon from '../../../util/icons/components/ArrowIcon';
import LearningCenterIcon from './LearningCenterIcon';
// import GradingIcon from './GradingIcon';
// import WhereToBuyIcon from './WhereToBuyIcon';
// import BuyCarcassesIcon from './BuyCarcassesIcon';
import OurFarmsIcon from './OurFarmsIcon';
import BuyBeefIcon from './BuyBeefIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import RecipesIcon from './RecipesIcon';
// import { getBeefBookDomain } from '../../../util/site';

const ConsumerDashboard = ({ match, location }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const meta = 'GoodBeef Index provides a range of resources ' +
    'for beef consumers. Learn how quality beef is graded, find ' +
    'out where to buy and more';
  return (
    <div id="consumer-dashboard" className="main-dashboard">
      <Helmet>
        <title>
          GoodBeef Index Consumer Dashboard
        </title>
        <meta name="description" content={ meta } />
        <link rel="canonical"
          href={ `${window.location.origin}/${location.pathname}`} />
      </Helmet>
      <h2 className="sub-title">GoodBeef Index for consumers</h2>
      <h1 className="main-title">
        good beef is good for you
      </h1>
      <div className="menu">
        <Link className="menu-item" to='/good-beef'>
          <div className="icon-wrapper learning-center">
            <LearningCenterIcon />
          </div>
          <div className="text">
            <h3 className="menu-title">Learning Center</h3>
            <h5 className="find-out-more">
              <span className="text">Find out more</span>
              <ArrowIcon />
            </h5>
          </div>
        </Link>
        {/* <a className="menu-item">
          <div className="icon-wrapper learning-center">
            <GradingIcon />
          </div>
          <div className="text">
            <h3 className="menu-title">Grading</h3>
            <h5 className="find-out-more">
              <span className="text">Find out more</span>
              <ArrowIcon />
            </h5>
          </div>
        </a> */}

        <Link to="/recipes" className="menu-item" data-cy="recipes-menu-item">
          <div className="icon-wrapper recipes">
            <RecipesIcon />
          </div>
          <div className="text">
            <h3 className="menu-title">Recipes</h3>
            <h5 className="find-out-more">
              <span className="text">Find out more</span>
              <ArrowIcon />
            </h5>
          </div>
        </Link>

        <Link to={ `${match.url}/where-to-buy`} className="menu-item">
          <div className="icon-wrapper learning-center">
            <BuyBeefIcon />
          </div>
          <div className="text">
            <h3 className="menu-title">Where to Buy Beef</h3>
            <h5 className="find-out-more">
              <span className="text">Find out more</span>
              <ArrowIcon />
            </h5>
          </div>
        </Link>

        {/* <a href={ `${getBeefBookDomain()}/cattle/finished-cattle` }
          className="menu-item" >
          <div className="icon-wrapper learning-center">
            <BuyCarcassesIcon />
          </div>
          <div className="text">
            <h3 className="menu-title">Animal Listings</h3>
            <h5 className="find-out-more">
              <span className="text">Find out more</span>
              <ArrowIcon />
            </h5>
          </div>
        </a> */}

        <Link to={ `${match.url}/our-farms`}
          style={{ display: 'none' }}
          className="menu-item">
          <div className="icon-wrapper learning-center">
            <OurFarmsIcon />
          </div>
          <div className="text">
            <h3 className="menu-title">Our Farms</h3>
            <h5 className="find-out-more">
              <span className="text">Find out more</span>
              <ArrowIcon />
            </h5>
          </div>
        </Link>
      </div>
    </div>
  );
};

ConsumerDashboard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default ConsumerDashboard;
