import React from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '../../util/icons/components/ArrowIcon';
import './outlet-popout.scss';
import { outletCategories } from '../outletCategories';

const OutletPopout = ({ outlet }) => {
  const link = outlet.url ? (
    <a className="link"
      href={ outlet.url }
      target="_blank"
      rel="noopener noreferrer">
      <span className="find-out-more-text">Find out more</span>
      <ArrowIcon />
    </a>
  ) : null;
  const description = outlet.description ? (
    <div className="description">{ outlet.description }</div>
  ) : null;
  return (
    <div className="outlet-popout">
      <div className="category">{ outletCategories[outlet.category] }</div>
      <div className="name">{ outlet.name }</div>
      { description }
      { link }
    </div>
  );
};

OutletPopout.propTypes = {
  outlet: PropTypes.object
};

export default OutletPopout;
