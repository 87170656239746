import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import ConsumerDashboard from './ConsumerDashboard';
import OurFarmsPage from '../../../pages/static/OurFarmsPage';
import WhereToBuyMap from '../../../wheretobuy/compontents/WhereToBuyMap';

const ConsumerSection = ({ match }) => {
  return (
    <Switch>
      <Route exact path={ match.url } render={
        props => <ConsumerDashboard { ...props } /> } />
      <Route path={ `${match.url}/our-farms` } render={
        props => <OurFarmsPage { ...props } /> } />
      <Route path={ `${match.url}/where-to-buy` } render={
        props => <WhereToBuyMap { ...props } /> } />
    </Switch>
  );
};

ConsumerSection.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default ConsumerSection;
