/* eslint-disable max-len */
import React from 'react';

const BuyBeefIcon = () => (
  <svg className="dashboard-icon" viewBox="0 0 127.49 137.87">
    <path className="background" d="M161.75,114.47C157.12,95,139.33,84.24,116.23,83.3l6.21-10.42a25.59,25.59,0,1,0-44,0l8.32,14C58.91,94.28,38.3,111.15,38.3,126.43V133a18.35,18.35,0,0,0,1.25,6.91c2.77,6.81,9.13,10.29,20.11,8.39,8.24-1.42,17.19-2.91,27.51,6.56s24.71,16.52,42.21,13.5c15.09-2.6,32.6-15,33.41-34.63v-9A43.47,43.47,0,0,0,161.75,114.47Z" transform="translate(-36.8 -32.69)"/>
    <path className="line" d="M111,58.75a10.58,10.58,0,1,0-10.58,10.58A10.59,10.59,0,0,0,111,58.75Zm-18.16,0a7.58,7.58,0,1,1,7.58,7.58A7.59,7.59,0,0,1,92.88,58.75Z" transform="translate(-36.8 -32.69)"/>
    <path className="line" d="M163.21,114.12c-4.41-18.58-20.93-30.49-44.42-32.18l4.94-8.29h0a27.09,27.09,0,1,0-46.55,0L84.5,85.92c-27.76,7.9-47.7,24.75-47.7,40.51V133a19.72,19.72,0,0,0,1.36,7.47c2.27,5.57,7.89,11.7,21.76,9.31,8.07-1.39,16.41-2.83,26.24,6.19,7.61,7,19.48,14.61,35,14.61a50.26,50.26,0,0,0,8.46-.74,46.09,46.09,0,0,0,22.82-11.32,35.25,35.25,0,0,0,11.83-24.79v-9A45.34,45.34,0,0,0,163.21,114.12ZM77.1,53.87a24.09,24.09,0,1,1,44.05,18.25l-20.69,34.72L79.76,72.11A23.93,23.93,0,0,1,77.1,53.87Zm21,54.84a2.94,2.94,0,0,0,1,1,2.73,2.73,0,0,0,2.1.3,2.78,2.78,0,0,0,1.7-1.26L114.11,89.8c16.61.41,35.52,7.12,39.75,24.82,2.08,8.67.65,16.69-4.11,23.16-5.21,7.08-14.19,11.87-23.44,12.5-13.41.91-25.36-3.5-36.52-13.48-7.44-6.65-17.1-9-29.53-7.16-8,1.18-11.59,1.45-12.93-4.58-2.2-9.93,17.13-24.61,41.59-31.72Zm31.05,58.16c-18.54,3.2-32.5-5.37-40.94-13.12-10.91-10-20.41-8.38-28.78-6.93-9.56,1.65-15.77-.87-18.47-7.49A16.63,16.63,0,0,1,39.8,133v-6.56c0-14.35,19.82-30.5,46.29-37.84l1.24,2.09c-11.57,3.45-22.67,8.81-30.7,14.85-9.23,6.94-13.57,14.11-12.22,20.18,2,9,8.94,8,16.29,6.9,11.49-1.7,20.35.41,27.09,6.43,10.77,9.64,22.28,14.36,35.08,14.36,1.2,0,2.42,0,3.64-.13,10.11-.68,19.94-5.94,25.66-13.71,5.3-7.21,6.9-16.08,4.61-25.64C153,98.08,137.42,87.86,115.86,86.85l1.2-2c22.92,1.2,39.05,12.35,43.23,30a41.84,41.84,0,0,1,1,9.9v8.93C160.72,147.5,149.49,163.36,129.13,166.87Z" transform="translate(-36.8 -32.69)"/>
    <path className="line" d="M132.93,131.42a10.84,10.84,0,1,0-10.83-10.83A10.84,10.84,0,0,0,132.93,131.42Zm0-18.67a7.84,7.84,0,1,1-7.83,7.84A7.84,7.84,0,0,1,132.93,112.75Z" transform="translate(-36.8 -32.69)"/>
  </svg>
);

export default BuyBeefIcon;
