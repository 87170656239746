import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxAndLabel from '../../util/form/components/CheckboxAndLabel';
import { setOutletFilter } from '../actions';
import { outletCategories } from '../outletCategories';
import { getCategoryFilters } from '../reducer';
import LocationSearchBox from './LocationSearchBox';
import './map-controls.scss';
import PropTypes from 'prop-types';
import ChevronIcon from '../../util/icons/components/ChevronIcon';

const MapControls = ({ map }) => {
  const [ visible, setVisible ] = useState(window.innerHeight > 7000);
  const dispatch = useDispatch();
  const categoryFilters = useSelector(getCategoryFilters);
  const categoryOptions = Object.keys(outletCategories).map(category => {
    const filters = [ ...categoryFilters ];
    const index = filters.indexOf(category);
    const toggleFunc = () => {
      if (index === -1) {
        dispatch(setOutletFilter([ ...filters, category ]));
      } else {
        filters.splice(index, 1);
        dispatch(setOutletFilter(filters));
      }
    };
    return (
      <CheckboxAndLabel
        key={ category }
        label={ outletCategories[category] }
        isChecked={ index !== -1 }
        toggle={ toggleFunc } />
    );
  });
  const showHideClass = visible ? 'open' : 'closed';
  const handleChevronClicked = () => {
    if (visible) {
      const map = document.getElementById('where-to-buy-map');
      map.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      const controls = document.getElementById('outlet-map-controls');
      controls.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
    setVisible(!visible);
  };
  return (
    <div className="outlet-map-controls" id="outlet-map-controls">
      <div className="show-hide-wrapper">
        <ChevronIcon
          className={ showHideClass }
          onClick={ handleChevronClicked } />
      </div>
      <div className="search-section">
        <h5 className="map-control-label">location</h5>
        <LocationSearchBox map={ map } />
      </div>
      <div className="shop-types">
        <h5 className="map-control-label">show me</h5>
        <div className="category-filters">
          { categoryOptions }
        </div>
      </div>
    </div>
  );
};

MapControls.propTypes = {
  map: PropTypes.object
};

export default MapControls;
