import React, { useEffect } from 'react';
import './static-promo-page.scss';

const OurFarmsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  return (
    <div className="promo-page">
      <div className="standard">
        <h3 className="header-3">
        GoodBeef Index Farms
        </h3>
        <p className="para">
          { `The GoodBeef Index has been working closely with a select group
          of beef farmers in the south west of England.` }
        </p>
        <p className="para">
          { `Check back soon. We'll be publicising details of all the farms
          that authenticate their beef with the our grading system here` }
        </p>
      </div>
    </div>
  );
};

export default OurFarmsPage;
