// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutlets } from '../actions';
import { getGeoJSONFarms, getGeoJSONOutlets } from '../reducer';
import './where-to-buy-map.scss';
import marker from '../../../img/icons/map-marker.png';
import farmMarker from '../../../img/icons/farm-marker.png';
import OutletPopout from './OutletPopout';
import MapControls from './MapControls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const WhereToBuyMap = ({ location }) => {
  const [map, setMap] = useState(null);
  const [popup, setPopup] = useState(null);
  const mapContainer = useRef(null);
  const dispatch = useDispatch();
  const outlets = useSelector(getGeoJSONOutlets);
  const farms = useSelector(getGeoJSONFarms);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    dispatch(fetchOutlets());
    return () => map ? map.remove() : null;
  }, []);
  useEffect(() => {
    mapboxgl.accessToken = process.env.mapboxKey;
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-2.539996, 54.059388],
        zoom: 5
      });

      map.on('load', () => {
        setMap(map);
        map.resize();

        // sales outlets
        map.loadImage(marker, (error, image) => {
          if (error) throw error;
          map.addImage('outlet-marker', image);
          map.addSource('outlets', {
            type: 'geojson',
            data: outlets
          });
          map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'outlets',
            'layout': {
              'icon-image': 'outlet-marker',
              'icon-anchor': 'bottom',
              'icon-size': 0.5,
              'icon-allow-overlap': true
            }
          });
          map.on('click', 'points', function (e) {
            var coordinates = e.features[0].geometry.coordinates.slice();
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            const content = document.createElement('div');
            ReactDOM.render(
              <OutletPopout outlet={ e.features[0].properties } />,
              content
            );
            const popupParams = window.innerWidth < 500 ? {
              offset: [ 0, -5 ]
            } : {
              offset: [0, -30],
              anchor: 'bottom'
            };
            const newPopup = new mapboxgl.Popup(popupParams)
              .setLngLat(coordinates)
              .setDOMContent(content)
              .addTo(map);
            if (popup) {
              popup.remove();
            }
            setPopup(newPopup);
          });
          map.on('mouseenter', 'points', function () {
            map.getCanvas().style.cursor = 'pointer';
          });
          map.on('mouseleave', 'points', function () {
            map.getCanvas().style.cursor = '';
          });
        });
        map.fitBounds([
          [-5.696113, 51.436434],
          [-1.815758, 49.917616]
        ]);
      });

      // farms
      map.loadImage(farmMarker, (error, image) => {
        if (error) throw error;
        map.addImage('farm-marker', image);
        map.addSource('farms', {
          type: 'geojson',
          data: farms
        });
        map.addLayer({
          'id': 'farm-points',
          'type': 'symbol',
          'source': 'farms',
          'layout': {
            'icon-image': 'farm-marker',
            'icon-anchor': 'bottom',
            'icon-size': 0.5,
            'icon-allow-overlap': true
          }
        });
        map.on('click', 'farm-points', function (e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          const content = document.createElement('div');
          ReactDOM.render(
            <OutletPopout outlet={ e.features[0].properties } />,
            content
          );
          const popupParams = window.innerWidth < 500 ? {
            offset: [ 0, -5 ]
          } : {
            offset: [0, -30],
            anchor: 'bottom'
          };
          const newPopup = new mapboxgl.Popup(popupParams)
            .setLngLat(coordinates)
            .setDOMContent(content)
            .addTo(map);
          if (popup) {
            popup.remove();
          }
          setPopup(newPopup);
        });
        map.on('mouseenter', 'farm-points', function () {
          map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'farm-points', function () {
          map.getCanvas().style.cursor = '';
        });
      });
    };
    if (!map) {
      initializeMap({ setMap, mapContainer });
    } else if (map.getSource('outlets')) {
      map.getSource('outlets').setData(outlets);
      if (map.getSource('farms')) {
        map.getSource('farms').setData(farms);
      }
      if (popup) {
        popup.remove();
        setPopup(null);
      }
    } else if (map.getSource('farms')) {
      map.getSource('farms').setData(farms);
      if (popup) {
        popup.remove();
        setPopup(null);
      }
    }
  }, [map, outlets, farms]);
  const meta = `
    Produced responsibly, beef is good for you and good for the planet. 
    Find out where you can source authentic GoodBeef Index graded beef.
  `;
  return (
    <div className="where-to-buy-map" id="where-to-buy-map">
      <Helmet>
        <title>
          Where to Buy Good Beef | GoodBeef Index
        </title>
        <meta name="description" content={ meta } />
        <link rel="canonical"
          href={ `${window.location.origin}/${location.pathname}`} />
      </Helmet>
      <div className="map-container"
        ref={el => (mapContainer.current = el) }></div>
      <MapControls map={ map }/>
    </div>
  );
};

WhereToBuyMap.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default WhereToBuyMap;
