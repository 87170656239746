import axios from 'axios';
import React, { useRef, useState } from 'react';
import SearchIcon from '../../util/icons/components/SearchIcon';
import './location-search-box.scss';
import PropTypes from 'prop-types';

const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

const LocationSearchBox = ({ map }) => {
  const inputRef = useRef();
  const [ text, setText ] = useState('');
  const [ suggestions, setSuggestions ] = useState([]);
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const [ placeholder, setPlaceholder ] = useState('Search by Place');
  let cancelToken;
  const handleChange = async (event) => {
    setText(event.target.value);
    if (typeof cancelToken !== 'undefined') {
      cancelToken.cancel('Canceled due to new request');
    }
    if (text && text.length > 3) {
      cancelToken = axios.CancelToken.source();
      const query = encodeURIComponent(text);
      try {
        const results = await axios.get(
          `${baseUrl}${query}.json?access_token=${process.env.mapboxKey}`,
          { cancelToken: cancelToken.token }
        );
        // console.log('results', results);
        setSuggestions(results.data.features);
        setSelectedIndex(0);
      } catch (error) {
        // console.log('error', error);
      }
    } else {
      setSuggestions([]);
      setSelectedIndex(0);
    }
  };
  const handleKeyDown = event => {
    if (event.keyCode === 13) { // return
      selectPlace(suggestions[selectedIndex]);
      event.target.blur();
    } else if (event.keyCode === 38) { // arrow up
      let newIndex = selectedIndex - 1;
      if (newIndex === -1) {
        newIndex = suggestions.length - 1;
      }
      setSelectedIndex(newIndex);
    } else if (event.keyCode === 40) { // arrow down
      let newIndex = selectedIndex + 1;
      if (newIndex > (suggestions.length - 1)) {
        newIndex = 0;
      }
      setSelectedIndex(newIndex);
    }
  };
  const selectPlace = place => {
    setSelectedIndex(0);
    setSuggestions([]);
    setText('');
    setPlaceholder(place.place_name);
    // console.log('map in selectPlace', map);
    if (map) {
      if (place.bbox) {
        // const b = place.bbox;
        // console.log([
        //   [place.bbox[0], place.bbox[1]],
        //   [place.bbox[2], place.bbox[3]]
        // ]);
        map.fitBounds([
          [place.bbox[0], place.bbox[1]],
          [place.bbox[2], place.bbox[3]]
        ]);
      } else if (place.center) {
        map.flyTo({ center: place.center, zoom: 12 });
      }
    }
    // console.log('setting place', place);
  };
  const suggestionOptions = suggestions.map((suggestion, index) => {
    const className = index === selectedIndex ?
      'suggestion selected' : 'suggestion';
    // console.log(suggestion);
    return (
      <div
        className={ className }
        onClick={ () => selectPlace(suggestion) }
        key={JSON.stringify(suggestion)}>
        { suggestion.place_name }
      </div>
    );
  });
  const options = suggestionOptions.length > 0 ? (
    <div className="suggestion-options">
      { suggestionOptions }
    </div>
  ) : null;
  const inputClass = suggestionOptions.length > 0 ? 'showing-dropdown' : null;
  return (
    <div className="location-search-box-wrapper">
      <input ref={ inputRef }
        placeholder={ placeholder }
        value={ text }
        className={ inputClass }
        onKeyDown={ handleKeyDown }
        onChange={ handleChange }/>
      <SearchIcon />
      { options }
    </div>
  );
};

LocationSearchBox.propTypes = {
  map: PropTypes.object
};

export default LocationSearchBox;
